import { ReferenceInput, AutocompleteInput, BulkDeleteButton, BooleanInput, SelectInput, Datagrid, TabbedForm, FormTab, List, FunctionField, TextField, NumberField, DateField, Edit, Show, TextInput, Filter, DateInput, NumberInput, Create, Button, DeleteButton, SaveButton, Toolbar } from "ra-ui-materialui";
import { useState, useCallback, useEffect, Fragment } from "react";
//import { getContractType, } from "../services/master-tables.service";
import { useLocation } from 'react-router';
import {
    // SaveButton,
    useRefresh,
    useNotify,
    useCreate,
    useUpdate,
    useRecordContext,
    useTranslate,
    useListContext
} from "react-admin";
import { required, useRedirect, useMutation, usePermissions } from "ra-core";
import { CustomToolbarPermissions } from "./utils";
//import { maxValue, number } from "ra-core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import DoneIcon from '@material-ui/icons/Done';
import ActionDelete from '@material-ui/icons/Delete';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { useForm, useFormState } from 'react-final-form';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogActions from '@material-ui/core/DialogActions';
 import CloseIcon from '@material-ui/icons/Close';
import moment from "moment";
// import contractsProvider from "../provider/contracts.provider";
import { TabsUseStyles } from "../App";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { withStyles, makeStyles } from '@material-ui/core/styles';

import userProvider from "../provider/user.provider"
import professionalProvider from "../provider/professional.provider";
import { hasAccess } from "ra-auth-acl";


const validateContract = (values) => {
    const errors = {};
    if (!values.contractNumber?.trim()) {
        errors.contractNumber = 'Requerido';
    }
    if (!values.initialDate) {
        errors.initialDate = 'Requerido';
    }
    if (moment(values.initialDate).isBefore("2021-01-01")) {
        errors.initialDate = 'La fecha no puede ser anterior a 2021';
    }
    if (moment(values.initialDate).isAfter(moment(Date.now()).add(parseInt(process.env.REACT_APP_END_DATE_LIMIT), "years"))) {
        errors.initialDate = `La fecha no puede ser superior a ${parseInt(process.env.REACT_APP_END_DATE_LIMIT)} años`;
    }
    if (values.maxClient < 0) {
        errors.maxClient = 'Este valor no puede ser negativo';
    }
    if (values.extensionDurationMonths < 0) {
        errors.extensionDurationMonths = 'Este valor no puede ser negativo';
    }
    if (values.publicAdministration) {
        if (!values.endDate) {
            errors.endDate = 'Requerido';
        }
        if (moment(values.endDate).isBefore("2021-01-01")) {
            errors.endDate = 'La fecha no puede ser anterior a 2021';
        }
        if (moment(values.endDate).isBefore(values.initialDate)) {
            errors.endDate = 'La fecha no puede ser anterior a la de inicio';
        }
        if (moment(values.endDate).isAfter(moment(Date.now()).add(parseInt(process.env.REACT_APP_END_DATE_LIMIT), "years"))) {
            errors.endDate = `La fecha no puede ser superior a ${parseInt(process.env.REACT_APP_END_DATE_LIMIT)} años`;
        }
    }


    return errors
}


export const CustomPostBulkActionButtonsPermision = props => {
    const permissions = usePermissions();
    const translate = useTranslate();
    const notify = useNotify()

    const { selectedIds, data, onSelect } = useListContext();
    const hasParent = selectedIds.some(id => data[id]?.isParent);
     

    const filteredSelectedIds = selectedIds.filter(id => {
        const record = data[id];

        return !record?.isMainSomeUser && !record?.isMainSomeProfessional;
    });

    const onSelectIds = async () => {
        console.log("onSelect:");
        if(filteredSelectedIds !== undefined && filteredSelectedIds !== null && filteredSelectedIds.length > 0) {
            if (onSelect && typeof onSelect === 'function') {
                onSelect(filteredSelectedIds); // Actualiza la selección en el Datagrid
            }
        }
    };

    const onClickDelete = async() => {
        console.log('Continuando con la eliminación.');
        await onSelectIds();//Desmarcar los que no se eliminan
        let texto = filteredSelectedIds
        .map(id => data[id]?.contractNumber) // Suponiendo que el campo de nombre es "name"
        .filter(contractNumber => !!contractNumber) // Elimina nombres no definidos o falsy
        .join(', ');
        if(texto) notify(`Se elimino estos contratos: ${texto}`)

    };

    return (
        <Fragment>
            { 
            <BulkDeleteButton  
                confirmTitle={translate('contract.confirmTitle1')} 
                confirmContent={GetChildrenContractTextBulkAction(translate, hasParent, selectedIds, data, filteredSelectedIds)} 
                undoable={false} {...props} 
                selectedIds={filteredSelectedIds}
                onClick={onClickDelete}
                disabled={!hasAccess(permissions.permissions, props.perm)} /> }
        </Fragment>
    )
};

const GetChildrenContractTextBulkAction = (translate, hasParent, selectedIds, data, filteredSelectedIds) => {

    //filtramos los contratos que tengan un usuario o profesional como contrato profesional
    const filterContractIds = selectedIds.filter(id => {
        const record = data !==undefined ? data[id] : null;

        return record?.isMainSomeUser || record?.isMainSomeProfessional;
    });


    const listItems = filterContractIds?.map((item, index) => (
        <li key={index} className="contract-item">
            <div className="item-name">{data[item].contractNumber}</div>
            <div className="item-status">
                {data[item].isMainSomeUser ? (
                    <>
                        <DoneIcon style={{color: 'green'}}/>
                    </>
                ) : (
                    <>
                        <CloseIcon style={{color: 'red'}}/>
                    </>
                )}
            </div>
            <div className="item-status">
                {data[item].isMainSomeProfessional ? (
                    <>
                        <DoneIcon style={{color: 'green'}}/>
                    </>
                ) : (
                    <>
                        <CloseIcon style={{color: 'red'}}/>
                    </>
                )}
            </div>
        </li>
        
    ));
    

    return (
            <div className="container-contract">
                {hasParent && (<p className="message">{translate('contract.deleteConfirm4')}</p>)}
                {
                    filterContractIds !== undefined && filterContractIds !== null && filterContractIds?.length > 0 && (
                        <div>
                            <p className="message">{translate('contract.fixedText2')}</p>
                            <ul className="contract-list">
                                <li className="contract-header">
                                    <div className="header-name">Contrato</div>
                                    <div className="header-status">Usuario</div>
                                    <div className="header-status">Profesional</div>
                                </li>
                                {listItems}
                            </ul>
                            <span className="description-table">Se marca con un check si el contrato tiene Usuario o profesional</span>
                        </div>
                    )
                }
                {
                    (filteredSelectedIds?.length === 1) ? 
                    <p className="message">{translate('contract.deleteConfirm3')}</p> 
                    : (
                        filteredSelectedIds?.length > 0 ? (
                            <p className="message">{`¿Seguro que quiere eliminar estos ${filteredSelectedIds?.length} contratos?`}</p>
                           ) : (
                            <p className="message">{`No se eliminaran contractos.`}</p>
                           )
                    )
                   
                }
                
            </div>
    );
};


export const ContractList = props => {
  
    return (
    <List bulkActionButtons={props.permissions?.contracts?.delete ? <CustomPostBulkActionButtonsPermision props={props} perm="contracts.delete" /> : false} exporter={false} filters={<ContractsFilter />} filterDefaultValues={{ isValidity: false }}{...props}>
        <Datagrid rowClick={props.permissions?.contracts?.edit ? "edit" : "show"} isRowSelectable={record => props.permissions?.contracts?.delete}>
            <TextField label="Contrato" source="contractNumber" />
            <DateField label="Fecha Inicio" source="initialDate" />
            <DateField label="Fecha Fin" source="endDate" />    
            <FunctionField label="Contrato Activo" source="isExpired" render={record => record?.isExpired === true ? 
                        <Typography >No</Typography>:
                        <Typography >Si</Typography>
                    } />  
            <FunctionField label="Tipo de cliente" source="publicAdministration" render={record => record?.publicAdministration === true ? 
                    <Typography >Público</Typography>:
                    <Typography >Privado</Typography>
                } />                 
            <FunctionField label="Es Padre" source="isParent" render={record => record?.isParent === true ? 
                        <Typography >Si</Typography>:
                        <Typography >No</Typography>
                    } />   
            <NumberField label="Max. Clientes" source="maxClient" />
        </Datagrid>
    </List>
    )
    
};

export const ContractEdit = (props) => {

    return (
        <Edit title={<ContractTitle />} mutationMode="pessimistic" {...props} transform={transformContractEdit}>
            {ContractForm(props, 'edit')}
        </Edit>
    )
};

export const ContractShow = (props) => {

    return (
        <Show title={<ContractTitle />} {...props}>
            {ContractShowForm(props)}
        </Show>
    )
};

const ContractTitle = ({ record }) => {
    return <span>Contrato {record ? `${record.contractNumber}` : ''}</span>;
};

export const ContractCreate = (props) => {

    return (
        <Create mutationMode="pessimistic" {...props} transform={transformContractEdit}>
            {ContractForm(props, 'create')}
        </Create>
    )
};

const ContractForm = (props, mode = 'create') => {
    const classes = TabsUseStyles();

    const redirect = useRedirect();
    const notify = useNotify()
    const [mutate] = useMutation();

    const save = useCallback(
        async (values) => {
            try {
                const res = await mutate({
                    type: mode === 'create' ? 'create' : 'update',
                    resource: 'contracts',
                    payload: { data: transformContractEdit(values) },
                }, { returnPromise: true });
                notify(`ra.notification.${mode === 'create' ? 'created' : 'updated_item'}`)
                if (mode === 'create') {
                    redirect('edit', props.basePath, res.data.id, res.data)
                } else {
                    redirect('list', props.basePath)
                }
            } catch (error) {
                if (error.error && error.error.code === 4003) {
                    //notify(error.error.message, 'error')
                    notify('ra.notification.http_error', 'warning')
                    return {
                        contractNumber: error.error.message
                    };
                }
                if (error.error && error.error.code === 4001) {
                    notify(error.error.message, 'error')
                    return
                }
            }
        },
        [mutate, mode, notify, props.basePath, redirect],
    );

    return (
        <TabbedForm classes={classes} validate={validateContract} save={save} toolbar={<CustomToolbarContract props={props}  perm="contracts.delete" />}>
            <FormTab label="Datos Generales" contentClassName="tab-container">
                <Box style={{ width: 100 + '%' }}>
                    <TextInput label="Contrato" source="contractNumber" />
                    <BooleanInput label="Admon. Pública" source="publicAdministration" style={{ paddingLeft: 30 + 'px' }} />
                </Box>
                <ReferenceInput label="Cliente" source="contractingAuthorityId" reference="contractingAuthorities" validate={[required()]}>
                    <AutocompleteInput optionText={(record) => record.name} resettable={true} />
                </ReferenceInput>
                <DateInput label="Inicio" source="initialDate" />
                <NumberInput label="Máx. Usuarios" source="maxClient" />
                <EndDateInput label="Fin" source="endDate" mode={mode} />
                <Box style={{ width: 100 + '%' }}>
                    <BooleanInput label="Es Padre" source="isParent" style={{ paddingLeft: 30 + 'px' }} />
                    <ReferenceInputIsParent isDisabled={false}></ReferenceInputIsParent>
                </Box>
                <ExtensionDurationInput label="Duración (meses)" source="extensionDurationMonths" />
            </FormTab>
            {
                (mode !== 'create') ?
                    <FormTab label="Profesionales" contentClassName="">
                        <ProfessionalsTable />
                    </FormTab>
                    : null
            }
            {
                (mode !== 'create') ?
                    <FormTab label="Usuarios" contentClassName="">
                        <UsersTable></UsersTable>
                    </FormTab>
                    : null
            }
        </TabbedForm>
    )
}


const useStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});

export const CustomToolbarContract = props => {
    const permissions = usePermissions()
    const style = useStyles();
    const record = useRecordContext(props)
    const translate = useTranslate();

    // Ensure that hooks are not called conditionally
    if (!permissions) return;
    if (!record) return;

    return (
        <Toolbar {...props} classes={style}>
            <SaveButton disabled={props.pristine} />
            {record?.childrenContract?.length > 0 ? (
                <DeleteButton
                    confirmTitle={translate('contract.confirmTitle2')}
                    mutationMode="pessimistic"
                    redirect="list"
                    disabled={!hasAccess(permissions.permissions, props.perm)}
                    confirmContent={GetChildrenContractText(record, translate)}
                    confirmColor="warning"
                />
            ) : (
                <DeleteButton
                    confirmTitle={translate('contract.confirmTitle2')}
                    confirmContent={GeDefaultText(record, translate)}
                    mutationMode="pessimistic"
                    redirect="list"
                    disabled={!hasAccess(permissions.permissions, props.perm)}
                />
            )}
        </Toolbar>
    );
};


const GetChildrenContractText = (data, translate) => {
    
    const listItems = data?.childrenContract?.map((item, index) => (
        <li key={index} className="contract-item">{item}</li>
    ));

    let messageTitle = '';

    if(data?.professionalsId?.length > 0 && data?.usersId?.length === 0){
        messageTitle = translate('contract.fixedProfessionalText');
    } else if(data?.professionalsId?.length === 0 && data?.usersId?.length > 0){
        messageTitle = translate('contract.fixedUserText');
    } else if(data?.professionalsId?.length > 0 && data?.usersId?.length > 0){
        messageTitle = translate('contract.fixedUserAndProfessionalText');
    }

    return (
        <div className="container-contract">
            <p className="message">{translate('contract.fixedText')}</p>
            <ul className="contract-list">{listItems}</ul>
            <br />
            <p className="message-confirm1">{translate('contract.deleteConfirm1')}</p>
            <p className="message-confirm1">{messageTitle}</p>
            <p className="message-confirm2">{translate('contract.deleteConfirm2')}</p>
        </div>

    );
};

const GeDefaultText = (data, translate) => {
    
    let messageTitle = '';

    if(data?.professionalsId?.length > 0 && data?.usersId?.length === 0){
        messageTitle = translate('contract.fixedProfessionalText');
    } else if(data?.professionalsId?.length === 0 && data?.usersId?.length > 0){
        messageTitle = translate('contract.fixedUserText');
    } else if(data?.professionalsId?.length > 0 && data?.usersId?.length > 0){
        messageTitle = translate('contract.fixedUserAndProfessionalText');
    } 

    return (
        <div className="">
            {
                messageTitle && (<div><p className="message">{messageTitle}</p>
                    <br /></div>)
            }
            <p className="message-confirm2">{translate('contract.deleteConfirm3')}</p>
        </div>

    );
};

const ReferenceInputIsParent = props => {
    const { values } = useFormState();
    const { isDisabled } = props;
    const form = useForm();

    if(values.isParent) form.change('parentContractId', null);


    return (
        <ReferenceInput perPage={1000} label="Contrato Padre" source="parentContractId" reference="contracts"
            filterToQuery={(record) => {
                return { isParent: 'true', idNotInclude: values?.id }
            }}
            validate={[]}>
            <AutocompleteInput disabled={values.isParent || isDisabled}
                optionText="contractNumber"
                validate={[]}
                resettable={true}
            />
        </ReferenceInput>
    );
};

const ContractShowForm = (props, mode = 'create') => {
    const classes = TabsUseStyles();

    const redirect = useRedirect();
    const notify = useNotify()
    const [mutate] = useMutation();

    const save = useCallback(
        async (values) => {
            try {
                const res = await mutate({
                    type: mode === 'create' ? 'create' : 'update',
                    resource: 'contracts',
                    payload: { data: transformContractEdit(values) },
                }, { returnPromise: true });
                notify(`ra.notification.${mode === 'create' ? 'created' : 'updated_item'}`)
                if (mode === 'create') {
                    redirect('edit', props.basePath, res.data.id, res.data)
                } else {
                    redirect('list', props.basePath)
                }
            } catch (error) {
                if (error.error && error.error.code === 4003) {
                    //notify(error.error.message, 'error')
                    notify('ra.notification.http_error', 'warning')
                    return {
                        contractNumber: error.error.message
                    };
                }
                if (error.error && error.error.code === 4001) {
                    notify(error.error.message, 'error')
                    return
                }
            }
        },
        [mutate, mode, notify, props.basePath, redirect],
    );

    return (
        <TabbedForm classes={classes} validate={validateContract} save={save} toolbar={<CustomToolbarPermissions perm="contracts.delete" />}>
            <FormTab label="Datos Generales" contentClassName="tab-container">
                <Box style={{ width: 100 + '%' }}>
                    <TextInput label="Contrato" source="contractNumber" disabled/>
                    <BooleanInput label="Admon. Pública" source="publicAdministration" style={{ paddingLeft: 30 + 'px' }} disabled/>
                </Box>
                <ReferenceInput label="Cliente" source="contractingAuthorityId" reference="contractingAuthorities" validate={[required()]} >
                    <AutocompleteInput optionText={(record) => record.name} resettable={true} disabled/>
                </ReferenceInput>
                <DateInput label="Inicio" source="initialDate" disabled/>
                <NumberInput label="Máx. Usuarios" source="maxClient" disabled/>
                <EndDateInput label="Fin" source="endDate" mode={mode} disabled/>
                <Box style={{ width: 100 + '%' }}>
                    <BooleanInput label="Es Padre" source="isParent" style={{ paddingLeft: 30 + 'px' }} disabled/>
                    <ReferenceInputIsParent isDisabled={true}></ReferenceInputIsParent>
                </Box>
            </FormTab>
                    <FormTab label="Profesionales" contentClassName="">
                        <ProfessionalsTable />
                    </FormTab>
                    <FormTab label="Usuarios" contentClassName="">
                        <UsersTable></UsersTable>
                    </FormTab>
        </TabbedForm>
    )
}

const UsersTable = () => {
    const [usersData, SetUsersData] = useState([])
    const [activeFilter, SetActiveFilter] = useState("ACTIVE")
    const [rowsPerPage, setRowsPerPage] = useState(7);
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(0);

    const useStyles = makeStyles((theme) => ({
        paper: {
            border: '1px solid',
            padding: theme.spacing(1),
            backgroundColor: theme.palette.background.paper,
        },
    }));
    const classes = useStyles();
    const StyledTableCell = withStyles((theme) => ({
        head: {
            backgroundColor: 'white',
            color: theme.palette.common.black,
            fontSize: 14,
            padding: "3px 11px 3px 11px",
            gap: "10px",
            height: "10px",
            width: "250px"
        },
        body: {
            fontSize: 14,
            padding: "3px 16px 3px 16px",
            gap: "10px",
            height: "10px",
            width: "250px"
        },
    }))(TableCell);

    const StyledTableRow = withStyles((theme) => ({
        root: {
            // '&:nth-of-type(odd)': {
            //     backgroundColor: theme.palette.action.hover,
            // },
        },
    }))(TableRow);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };

    useEffect(() => {
        ContractUsersRequest()
        // eslint-disable-next-line
    }, [page, rowsPerPage, activeFilter])

    const record = useRecordContext()
    const ContractUsersRequest = async (filter) => {
        let res = await userProvider.getManyReference(null, {
            id: record.id,
            pagination: { page: page+1, perPage: rowsPerPage },
            filter: {active:activeFilter}
        })

        setTotal(res.total)
        SetUsersData(res.data)
    }

    const handleActiveFilterChange = (e) => {
        SetActiveFilter(e.target.value)
        setRowsPerPage(7);
        setPage(0)
    }

    const statusLabel = {
        ACTIVE: 'ACTIVO',
        INACTIVE: 'INACTIVO',
        LEAVE: 'BAJA',
    }

    return (
        <>
            <FormControl className={classes.formControl} style={{ width: "20rem" }}>
                <InputLabel id="demo-simple-select-label">Estado</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={activeFilter}
                    onChange={handleActiveFilterChange}
                >
                    <MenuItem value={"all"}>Todos</MenuItem>
                    <MenuItem value={"ACTIVE"}>Activo</MenuItem>
                    <MenuItem value={"INACTIVE"}>Inactivo</MenuItem>
                    <MenuItem value={"LEAVE"}>Baja</MenuItem>
                    {/* <MenuItem value={3}>Alimentación</MenuItem> */}
                </Select>
            </FormControl>
            <Table aria-label="customized table" style={{ whiteSpace: "nowrap", marginTop: "1rem", width: "100%" }}>
                <TableHead>
                    <TableRow>
                        <StyledTableCell align="left">Nombre</StyledTableCell>
                        <StyledTableCell align="left">Apellidos</StyledTableCell>
                        <StyledTableCell align="left">Email</StyledTableCell>
                        <StyledTableCell align="left" style={{ whiteSpace: "normal", width: "186px" }}>Estado</StyledTableCell>
                        {/* <StyledTableCell align="left" style={{ width: "230px" }}>Calidad descanso</StyledTableCell> */}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {usersData.map((row, index) => (
                        <StyledTableRow key={row.timestamp}>
                            <StyledTableCell align="left">{row.name}</StyledTableCell>
                            <StyledTableCell align="left">{row.surnames}</StyledTableCell>
                            <StyledTableCell align="left">{row.email}</StyledTableCell>
                            <StyledTableCell align="left">{statusLabel[row.active]}</StyledTableCell>
                        </StyledTableRow>
                    ))}
                    { !usersData.length?<TableRow><TableCell colSpan={6} align="center">No se encontraron resultados</TableCell></TableRow> : null }
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            count={total}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            rowsPerPageOptions={[7, 15, 30]}
                            labelRowsPerPage={<span>Filas por página:</span>}
                            labelDisplayedRows={({ from = page * rowsPerPage, to = rowsPerPage + rowsPerPage, count = usersData.length() }) => `Registros totales: ${count}      ---          Del ${from} al ${to} `}
                            backIconButtonProps={{
                                color: "secondary"
                            }}
                            nextIconButtonProps={{ color: "secondary" }}
                            SelectProps={{
                                inputProps: {
                                    "aria-label": "page number"
                                }
                            }}
                            showFirstButton={true}
                            showLastButton={true}
                        //ActionsComponent={TablePaginationActions}
                        //component={Box}
                        //sx and classes prop discussed in styling section
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </>
    )
}

const ProfessionalsTable = () => {
    const [professionalsData, SetProfessionalsData] = useState([])
    const [activeFilter, SetActiveFilter] = useState("true")
    const [rowsPerPage, setRowsPerPage] = useState(7);
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(0);

    const useStyles = makeStyles((theme) => ({
        paper: {
            border: '1px solid',
            padding: theme.spacing(1),
            backgroundColor: theme.palette.background.paper,
        },
    }));
    const classes = useStyles();
    const StyledTableCell = withStyles((theme) => ({
        head: {
            backgroundColor: 'white',
            color: theme.palette.common.black,
            fontSize: 14,
            padding: "3px 11px 3px 11px",
            gap: "10px",
            height: "10px",
            width: "250px"
        },
        body: {
            fontSize: 14,
            padding: "3px 16px 3px 16px",
            gap: "10px",
            height: "10px",
            width: "250px"
        },
    }))(TableCell);

    const StyledTableRow = withStyles((theme) => ({
        root: {
            // '&:nth-of-type(odd)': {
            //     backgroundColor: theme.palette.action.hover,
            // },
        },
    }))(TableRow);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0)
    };

    useEffect(() => {
        ContractProfessionalRequest()
        // eslint-disable-next-line
    }, [page, rowsPerPage, activeFilter])

    const record = useRecordContext()
    const ContractProfessionalRequest = async () => {
        let res = await professionalProvider.getManyReference(null, {
            id: record.id,
            pagination: { page: page+1, perPage: rowsPerPage },
            filter: {active:activeFilter}
        })

        setTotal(res.total)
        SetProfessionalsData(res.data)
    }

    const handleActiveFilterChange = (e) => {
        SetActiveFilter(e.target.value)
        setRowsPerPage(7);
        setPage(0)
    }

    return (
        <>
            <FormControl className={classes.formControl} style={{ width: "20rem" }}>
                <InputLabel id="demo-simple-select-label">Estado</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={activeFilter}
                    onChange={handleActiveFilterChange}
                >
                    <MenuItem value={"all"}>Todos</MenuItem>
                    <MenuItem value={"true"}>Activo</MenuItem>
                    <MenuItem value={"false"}>No Activo</MenuItem>
                </Select>
            </FormControl>
            <Table aria-label="customized table" style={{ whiteSpace: "nowrap", marginTop: "1rem", width: "100%" }}>
                <TableHead>
                    <TableRow>
                        <StyledTableCell align="left">Nombre</StyledTableCell>
                        <StyledTableCell align="left">Apellidos</StyledTableCell>
                        <StyledTableCell align="left">Email</StyledTableCell>
                        <StyledTableCell align="left" style={{ whiteSpace: "normal", width: "186px" }}>Activo</StyledTableCell>
                        {/* <StyledTableCell align="left" style={{ width: "230px" }}>Calidad descanso</StyledTableCell> */}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {professionalsData.map((row, index) => (
                        <StyledTableRow key={row.timestamp}>
                            <StyledTableCell align="left">{row.name}</StyledTableCell>
                            <StyledTableCell align="left">{row.surnames}</StyledTableCell>
                            <StyledTableCell align="left">{row.email}</StyledTableCell>
                            <StyledTableCell align="left">{row.active ? 'Sí' : 'No'}</StyledTableCell>
                        </StyledTableRow>
                    ))}
                    { !professionalsData.length?<TableRow><TableCell colSpan={6} align="center">No se encontraron resultados</TableCell></TableRow> : null }
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            count={total}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            rowsPerPageOptions={[7, 15, 30]}
                            labelRowsPerPage={<span>Filas por página:</span>}
                            labelDisplayedRows={({ from = page * rowsPerPage, to = rowsPerPage + rowsPerPage, count = professionalsData.length() }) => `Registros totales: ${count}      ---          Del ${from} al ${to} `}
                            backIconButtonProps={{
                                color: "secondary"
                            }}
                            nextIconButtonProps={{ color: "secondary" }}
                            SelectProps={{
                                inputProps: {
                                    "aria-label": "page number"
                                }
                            }}
                            showFirstButton={true}
                            showLastButton={true}
                        //ActionsComponent={TablePaginationActions}
                        //component={Box}
                        //sx and classes prop discussed in styling section
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </>
    )
}

const EndDateInput = props => {
    const { values } = useFormState();
    return (
        (values.publicAdministration) ?
            <DateInput {...props} /> : (props.mode !== 'create') ?
                <DateInput label="Fin" source="endDate" disabled />
                : null
    );
};
const ExtensionDurationInput = props => {
    const { values } = useFormState();
    return (
        (values.publicAdministration) ?
            <Box style={{ width: 100 + '%' }}>
                <BooleanInput label="Prórroga" source="extension" />
                <NumberInput disabled={!values.extension} {...props} />
            </Box> : null
    );
};

const ContractsFilter = (props) => {

    return (
        <Filter {...props}>
            <TextInput label="Contrato" source="contractNumber" alwaysOn />
            <DateInput label="Inicio" source="initialDate" />
            <DateInput label="Fin" source="endDate" />
            <SelectInput label="Contracto Activo" source="isExpired" choices={[
                { id: 'all', name: 'Todos' },
                { id: 'true', name: 'Si' },
                { id: 'false', name: 'No' }
            ]} />
            <SelectInput label="Tipo de cliente" source="isPublicAdministration" choices={[
                { id: 'all', name: 'Todos' },
                { id: 'true', name: 'Público' },
                { id: 'false', name: 'Privado' }
            ]} />
            <SelectInput label="Es Padre" source="isParent" choices={[
                { id: 'all', name: 'Todos' },
                { id: 'true', name: 'Si' },
                { id: 'false', name: 'No' }
            ]} />
            <NumberInput label="Máx. Clientes" source="maxClients" />
        </Filter>
    );
}

// const PostCreateToolbar = props => {
//     return (
//         <Toolbar {...props} >
//             <SaveButton
//                 label="Guardar"
//                 redirect={props.redirect}
//                 submitOnEnter={true}
//             />
//             <Button 
//                 label="Volver" 
//                 component={Link}
//                 to={{
//                     pathname: props.redirect,
//                     // Here we specify the initial record for the create view
//                 }}
//             ></Button>
//         </Toolbar>
//     )
// };

export const AddNewContractsButton = ({ record }) => {
    const [showDialog, setshowDialog] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)

    const location = useLocation();
    const refresh = useRefresh();
    const form = useForm();
    const notify = useNotify();
    const [create, { loading }] = useCreate('contracts');

    const handlePopOverClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopOverClose = () => {
        setAnchorEl(null);
    };

    const handleCloseClick = () => {
        setshowDialog(false);
    };

    const handleClick = (event) => {
        if (form.getState().dirty) {
            handlePopOverClick(event)
        } else {
            setshowDialog(true);
        }
    };

    const handleSubmit = async values => {
        create(
            { payload: { data: { ...values, clientId: record.id } } },
            {
                onSuccess: ({ data }) => {
                    setshowDialog(false);
                    // Update the comment form to target the newly created post
                    // Updating the ReferenceInput value will force it to reload the available posts
                    // refresh();
                    refresh();
                },
                onFailure: ({ error }) => {
                    notify(error.message, 'error');
                }
            }
        );
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <Button
                onClick={handleClick}
                label="Agregar contrato"
                icon="card-account-mail"
            >
                <AccountCircleIcon />
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopOverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Typography style={{ padding: 10 }}>Para poder agregar contratos es necesario que guarde la información del formulario antes.</Typography>
            </Popover>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
            >
                <DialogTitle>Añadir contrato</DialogTitle>
                <DialogContent>
                    <ContractForm save={handleSubmit} redirect={location.pathname} mode="create" loading={loading} handleCloseClick={handleCloseClick}></ContractForm>
                </DialogContent>
                {/* <DialogActions>
                    <Button
                        label="Salir"
                        onClick={handleCloseClick}
                    >
                        <CloseIcon />
                    </Button>
                </DialogActions> */}
            </Dialog>
        </>
    )
};

export const DeleteContractsButton = ({ record }) => {
    const location = useLocation();
    const form = useForm();
    const [anchorEl, setAnchorEl] = useState(null)

    const handlePopOverClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopOverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    if (form.getState().dirty) {
        return (
            <>
                <Button
                    onClick={handlePopOverClick}
                    label="Borrar"
                    className="my-ra-delete-button"
                    key="button"
                    color="error"
                >
                    <ActionDelete />
                </Button>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handlePopOverClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Typography style={{ padding: 10 }}>Para poder eliminar contratos es necesario que guarde la información del formulario antes.</Typography>
                </Popover>
            </>
        )
    } else {
        return (
            <>
                <DeleteButton
                    mutationMode="pessimistic"
                    resource="contracts"
                    record={record}
                    redirect={location.pathname}
                    label="borrar contrato"
                >
                    <AccountCircleIcon />
                </DeleteButton>
            </>
        )
    }
};

export const EditContractsButton = ({ record }) => {
    const [showDialog, setshowDialog] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)

    const location = useLocation();
    const refresh = useRefresh();
    const form = useForm();
    const notify = useNotify();
    const [edit, { loading }] = useUpdate('contracts');

    const handlePopOverClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopOverClose = () => {
        setAnchorEl(null);
    };

    const handleCloseClick = () => {
        setshowDialog(false);
    };

    const handleClick = (event) => {
        if (form.getState().dirty) {
            handlePopOverClick(event)
        } else {
            setshowDialog(true);
        }
    };

    const handleSubmit = async values => {
        edit(
            { payload: { data: transformContractEdit(values) } },
            {
                onSuccess: ({ data }) => {
                    setshowDialog(false);
                    // Update the comment form to target the newly created post
                    // Updating the ReferenceInput value will force it to reload the available posts
                    // refresh();
                    refresh();
                },
                onFailure: ({ error }) => {
                    notify(error.message, 'error');
                }
            }
        );
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <Button
                onClick={handleClick}
                label="Editar contrato"
                icon="card-account-mail"
            >
                <AccountCircleIcon />
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopOverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Typography style={{ padding: 10 }}>Para poder editar contratos es necesario que guarde la información del formulario antes.</Typography>
            </Popover>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
            >
                <DialogTitle>Editar contrato</DialogTitle>
                <DialogContent>
                    <ContractForm
                        save={handleSubmit}
                        redirect={location.pathname}
                        mode="create"
                        loading={loading}
                        handleCloseClick={handleCloseClick}
                        initialValues={record}
                    ></ContractForm>
                </DialogContent>
                {/* <DialogActions>
                    <Button
                        label="Salir"
                        onClick={handleCloseClick}
                    >
                        <CloseIcon />
                    </Button>
                </DialogActions> */}
            </Dialog>
        </>
    )
};

const transformContractEdit = (data) => {
    const initialDate = data.initialDate ? moment(data.initialDate).format('yyyy-MM-DDTHH:mm:ss') : null;
    const endDate = data.endDate ? moment(data.endDate).format('yyyy-MM-DDTHH:mm:ss') : null;
    return {
        ...data,
        initialDate,
        endDate,
    }
}