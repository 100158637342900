import { hasAccess } from "ra-auth-acl";
import { usePermissions } from "ra-core";
import { AutocompleteInput, BulkDeleteButton, DeleteWithConfirmButton, SaveButton, DeleteButton, Toolbar } from "ra-ui-materialui";
import { Fragment } from "react";
import { makeStyles } from '@material-ui/core/styles';
// import { ListButton } from "react-admin"

export const MapElementsToArray = (props) => {

}

export const MapAutocompleteInput = (props) => {
    console.log('Props: ', props);

    // Map prop source
    return (
        <span>
            <AutocompleteInput
                label={props.label}
                source={props.source}
                choices={props.choices}
                optionText={props.optionText}
                optionValue={props.optionValue}
                options={props.options}
            />
        </span>
    )
}

export const PostBulkActionButtons = props => (
    <Fragment>
        {/* default bulk delete action */}
        <BulkDeleteButton undoable={false} {...props} />
    </Fragment>
);

export const PostBulkActionButtonsPermision = props => {
    const permissions = usePermissions();
    return (
        <Fragment>
            {/* default bulk delete action */}
            <BulkDeleteButton undoable={false} {...props} disabled={!hasAccess(permissions.permissions, props.perm)} />
        </Fragment>
    )
};

const useStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});


export const CustomToolbarPermissions = props => {
    const permissions = usePermissions()
    const style = useStyles();
    if (!permissions) return
    return (
        <Toolbar {...props} classes={style}>
            <SaveButton disabled={props.pristine} />
            <DeleteButton mutationMode="pessimistic" redirect="list" disabled={!hasAccess(permissions.permissions, props.perm)} />
            {/* <ListButton basePath='/scheduledActivitiesOld' label="Atrás" icon={<arrow-left />} /> */}
        </Toolbar>
    )
};


export const CustomRecordToolbarPermissions = props => {
    const permissions = usePermissions()
    const style = useStyles();
    
    if (!permissions) return
    
    return (
        <Toolbar {...props} classes={style}>
            <SaveButton disabled={props.pristine} />
            <DeleteWithConfirmButton
                {...props}
                mutationMode={"pessimistic"}
                redirect={props?.redirect || "list"} 
                disabled={!hasAccess(permissions.permissions, props.perm)}
                onSuccess={() => props?.onsaved()}
            />
            {/* <ListButton basePath='/scheduledActivitiesOld' label="Atrás" icon={<arrow-left />} /> */}
        </Toolbar>
    )
};


export const CustomActivityToolbarPermissions = props => {
    const permissions = usePermissions()
    const style = useStyles();
    
    if (!permissions) return
    
    return (
        <>
        {
            !props?.record || !props?.record?.isDeleted ? (
                <Toolbar {...props} classes={style}>
                <SaveButton disabled={props.pristine} />
                <DeleteButton mutationMode="pessimistic" redirect="list" disabled={!hasAccess(permissions.permissions, props.perm)} />
            </Toolbar>
            ) : null
        }
        </>
        
    )
};


export const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});
